<template>
  <el-dialog
    :visible.sync="visible"
    :title="'设置部门'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
    :destroy-on-close="true"
  >
    <div>
      <div style="color: #000000">{{ organizationName }}</div>
      <el-tree
        class="tree"
        ref="config-tree"
        :data="treeData"
        :check-strictly="true"
        node-key="departmentId"
        @node-click="checkedNodes"
        @check="checkedNodes"
        :props="configProps"
        show-checkbox
      >
      </el-tree>
    </div>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="setDepartment">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import departmentApi from '@api/department'
import staffApi from '@api/staff'
export default {
  name: 'StaffDepartmentConfigDialog',
  data() {
    return {
      visible: false,
      mode: true,
      userId: '',
      organizationName: '',
      userIds: [],
      delpartmentIds: [], //默认选择的部门
      loading: false,
      treeData: [],
      configProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  methods: {
    checkedNodes(data) {
      if (this.userIds.length) {
        this.$refs['config-tree'].setCheckedNodes([data])
      }
    },
    setDepartment() {
      let departmentIds = this.$refs['config-tree'].getCheckedKeys()
      this.loading = true
      if (!this.userIds.length) {
        staffApi
          .modifyDepartment(this.userId, departmentIds)
          .then(res => {
            if (res.res === true) {
              this.$message.success('设置部门成功')
              this.$emit('refresh')
              this.visible = false
            } else {
              this.loading = false
            }
          })
          .catch(err => {
            this.loading = false
          })
      } else {
        staffApi
          .moveBatchStaff({ desDepartmentId: departmentIds[0], userIds: this.userIds })
          .then(res => {
            if (res.code === 0) {
              this.$message.success('批量设置部门成功')
              this.$emit('refresh')
              this.visible = false
            } else {
              this.loading = false
            }
          })
          .catch(err => {
            this.loading = false
          })
      }
    },
    getDepartment() {
      departmentApi.getDepartmentsTree().then(res => {
        this.treeData = res.res.children
      })
    },
    open(row, name, userIds) {
      this.getDepartment()
      this.organizationName = name
      this.userIds = userIds ? userIds : []
      if (row) {
        this.userId = row.userId
        let departmentIds = row.departmentVOS.map(item => {
          return item.departmentId
        })
        this.$nextTick(() => {
          this.$refs['config-tree'].setCheckedKeys(departmentIds) //获取已经设置的资源后渲染
        })
      }
      this.visible = true
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.tree {
  margin-top: 8px;
  height: calc(100% - 80px);
  overflow: auto;
}
::v-deep .el-dialog__body {
  margin-left: 39px !important;
  margin-top: 20px !important;
}
</style>
