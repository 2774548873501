<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新增员工' : '修改员工'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="staffForm" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="姓名" prop="realName">
        <el-input v-model="staffForm.realName" placeholder="真实姓名" />
        <div class="form-tip" v-if="!ruleValidate.realName.error"></div>
      </el-form-item>
      <el-form-item label="工号" prop="jobNumber">
        <el-input v-model="staffForm.jobNumber" maxlength="30" show-word-limit placeholder="员工工号" />
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="staffForm.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="0">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标签">
        <div class="tag-wrapper">
          <template v-for="(tag, index) in staffForm.tabIds">
            <el-select
              v-model="staffForm.tabIds[index]"
              placeholder="输入/选择"
              :key="index"
              :style="{
                marginRight: index + (1 % 3) === 0 ? '0' : '10px',
                marginBottom: index > staffForm.tabIds.length - 4 ? '0' : '10px'
              }"
              filterable
              :filter-method="searchTags"
              @change="handleSelect($event, index)"
              @visible-change="resetSelect"
              @mouseenter.native="tagDeleteVisible = index"
              @mouseleave.native="tagDeleteVisible = ''"
            >
              <div slot="prefix">
                <!--删除标签-->
                <i class="el-icon-error close-select-button" v-if="tagDeleteVisible === index" @click.stop="deleteTagSelect(index)"></i>
              </div>
              <el-option v-for="item in tagOptions" :key="item.tagId" :label="item.name" :value="item.tagId" style="width: 184px;">
                <span style="float: left;">{{ item.name }}</span>
                <span style="float: right;">
                  <i class="el-icon-check" v-if="tag === item.tagId" style="font-size: 4px;" />
                </span>
              </el-option>
            </el-select>
          </template>
          <i class="el-icon-circle-plus-outline add-select-button" v-if="staffForm.tabIds.length < 15" @click="addTagSelect"></i>
        </div>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="staffForm.email" placeholder="请输入邮箱" />
      </el-form-item>
      <el-form-item v-if="mode" label="密码" prop="password">
        <el-input v-model="staffForm.password" type="password" placeholder="请输入密码" />
        <div class="form-tip" v-if="!ruleValidate.password.error">
          8-20位，字母、数字、符号中任意2种
        </div>
      </el-form-item>
      <el-form-item label="手机" prop="phone">
        <el-input v-model="staffForm.phone" placeholder="请输入手机号" />
      </el-form-item>
      <el-form-item label="岗位" prop="postId">
        <el-select v-model="staffForm.postId" placeholder="请选择岗位">
          <el-option v-for="item in postData" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="招聘方式" prop="recruitWay">
        <el-select v-model="staffForm.recruitWay" placeholder="请选择招聘方式">
          <el-option label="校招" :value="0"></el-option>
          <el-option label="社招" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="简介" prop="description">
        <el-input v-model="staffForm.description" type="textarea" placeholder="请输入个人简介" />
        <div class="form-tip" v-if="!ruleValidate.description.error">
          不超过200个字
        </div>
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading" class="button">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import staffApi from '@api/staff'
import tagApi from '@api/tag'
import postApi from '@api/post'
export default {
  name: 'StaffCUDialog',
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('手机号码不能为空'))
      } else if (!/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(value)) {
        callback(new Error('请输入正确格式的手机号码'))
      } else {
        callback()
      }
    }
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!value) {
        that.ruleValidate.realName.error = true
        callback(new Error('姓名不能为空'))
      } else if (!reg.test(value)) {
        that.ruleValidate.realName.error = true
        callback(new Error('姓名不能全为空格'))
      } else if (value.length > 10) {
        that.ruleValidate.realName.error = true
        callback(new Error('姓名不超过10个字'))
      } else {
        that.ruleValidate.realName.error = false
        callback()
      }
    }

    const that = this

    return {
      deleteIndex: -1,
      tags: [],
      postData: [],
      tagOptions: [],
      tagOptionsCopy: [],
      visible: false,
      tagDeleteVisible: '',
      tagKeyword: '',
      loading: false,
      mode: true,
      recruitWay: null,
      // 员工弹窗表单
      staffForm: {
        realName: '',
        jobNumber: '',
        email: '',
        gender: 1,
        password: '',
        phone: '',
        newPhone: '',
        description: '',
        postId: '',
        tabIds: [''],
        departmentIds: []
      },
      ruleValidate: {
        postId: {
          required: true,
          message: '岗位不能为空',
          trigger: 'change'
        },
        realName: {
          required: true,
          error: false,
          validator: validateName
        },
        jobNumber: {
          required: true,
          message: '工号不能为空'
        },
        email: [
          {
            required: true,
            message: '邮箱不能为空'
          },
          {
            type: 'email',
            message: '请输入正确格式的邮箱'
          }
        ],
        password: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.length === 0) {
              that.ruleValidate.password.error = true
              callback(new Error('密码不能为空'))
            } else if (!/((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,20}$/.test(value)) {
              that.ruleValidate.password.error = true
              callback(new Error('密码不符合规则, 请填写8-20位字符，需包含字母、数字、符号中任意2种'))
            } else {
              that.ruleValidate.password.error = false
              callback()
            }
          }
        },
        phone: [
          {
            required: true,
            message: '手机号码不能为空'
          },
          {
            validator: validatePhone
          }
        ],
        description: {
          required: false,
          error: false,
          validator: (rule, value, callback) => {
            if (value && value.length > 200) {
              that.ruleValidate.description.error = true
              callback(new Error('简介不超过 200 字'))
            } else {
              that.ruleValidate.description.error = false
              callback()
            }
          }
        }
      }
    }
  },
  created() {
    this.getPostData()
    this.getTags()
  },
  methods: {
    getTags() {
      let payload = {
        keyword: '',
        typeId: this.ytConstant.tagType.USER
      }
      tagApi.searchTagByTypeId(payload).then(res => {
        if (res.code === 0) {
          this.tagOptions = res.res
          this.tagOptionsCopy = res.res
        }
      })
    },
    addTagSelect() {
      if (this.staffForm.tabIds[this.staffForm.tabIds.length - 1] === '') {
        this.$message.warning('请先选择上一个标签')
        return
      }
      this.staffForm.tabIds.push('')
      this.$forceUpdate()
    },
    deleteTagSelect(index) {
      this.staffForm.tabIds.splice(index, 1)
      if (this.staffForm.tabIds.length === 0) {
        this.staffForm.tabIds.push('')
      }
      this.$forceUpdate()
    },
    searchTags(keyword) {
      this.tagKeyword = keyword
      if (keyword === '') {
        this.tagOptions = this.tagOptionsCopy
      } else {
        this.tagOptions = this.tagOptionsCopy.filter(item => {
          if (!!~item.name.indexOf(keyword) || !!~item.name.toUpperCase().indexOf(keyword.toUpperCase())) {
            return true
          }
        })
      }
    },
    handleSelect(data, index) {
      let time = 0
      this.staffForm.tabIds.map(id => {
        if (id === data) {
          time++
        }
      })
      if (time > 1) {
        this.$message.warning('该标签已选择')
        this.staffForm.tabIds[index] = ''
      }
    },
    resetSelect(visible) {
      if (!visible) {
        this.tagOptions = this.tagOptionsCopy
      }
      this.$forceUpdate()
    },
    getPostData() {
      postApi.getPostByType(1).then(res => {
        this.postData = res.res
      })
    },
    initData(data) {
      this.staffForm = { ...data, postId: data.postVO.postId }
      this.visible = true
      this.mode = false
      //编辑标签
      if (data.tabVOS !== null) {
        this.staffForm.tabIds = data.tabVOS.map(tag => {
          return tag.tabId
        })
      } else {
        this.staffForm.tabIds = ['']
      }
    },
    open(mode, departmentId) {
      this.visible = true
      this.mode = mode
      if (mode) {
        this.staffForm.departmentIds = [departmentId]
      }
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
      this.staffForm.tabIds = ['']
      this.$refs.form.resetFields()
    },
    submit() {
      if (this.mode) {
        this.create()
      } else {
        this.update()
      }
    },
    create() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let postData = Object.assign({}, this.staffForm)
          postData.avatar = ''
          postData.departmentIds = this.staffForm.departmentIds
          postData.postId = this.staffForm.postId
          staffApi
            .insertStaff(postData)
            .then(res => {
              if (res.res === true) {
                this.$message.success('添加成功')
                this.visible = false
                this.$emit('on-success')
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    update() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let postData = Object.assign({}, this.staffForm)
          postData.avatar = ''
          postData.departmentIds = this.staffForm.departmentIds
          postData.postId = this.staffForm.postId
          staffApi
            .editStaff(postData)
            .then(res => {
              if (res.res === true) {
                this.$message.success('修改成功')
                this.visible = false
                this.$emit('on-success')
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
