import config from '../config/config'
import { axios } from './index'

const rolePermissionUrl = config.baseUrl + '/user/api/v2/role'

export default {
  //查询企业角色
  search(payload, pageNum, pageSize) {
    return axios.get(`${rolePermissionUrl}/get?page=${pageNum}&size=${pageSize}&roleName=${payload}`)
  },
  //添加角色
  add(payload) {
    return axios.post(`${rolePermissionUrl}/add?roleName=${payload}`)
  },
  //修改角色
  update(payload) {
    return axios.post(`${rolePermissionUrl}/update?roleName=${payload.name}&roleId=${payload.id}`)
  },
  //删除角色
  delete(payload) {
    return axios.post(`${rolePermissionUrl}/delete`, payload)
  },
  //获取所有角色
  getAllRoles() {
    return axios.get(`${rolePermissionUrl}/all/role/get`)
  },
  //用户绑定角色
  userBindRole(payload) {
    return axios.post(`${rolePermissionUrl}/user/bind/role`, payload)
  }
}
