<template>
  <el-dialog
    :visible.sync="visible"
    :title="'配置用户角色'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <div class="config-tree">
      <el-tree ref="config-tree" :data="treeData" node-key="roleId" :props="configProps" show-checkbox> </el-tree>
    </div>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import rolePermissionApi from '@api/rolePermission'
export default {
  name: 'StaffRoleConfigDialog',
  data() {
    return {
      visible: false,
      mode: true,
      loading: false,
      treeData: [],
      configProps: {
        children: 'children',
        label: 'roleName'
      },
      staffInfo: {}
    }
  },
  methods: {
    getAllRole() {
      rolePermissionApi.getAllRoles().then(res => {
        this.treeData = res.res
      })
    },
    userBindRoles() {
      let roleIds = this.$refs['config-tree'].getCheckedKeys()
      let payload = {
        departmentId: this.staffInfo.departmentId,
        roleIds: roleIds,
        userIds: [this.staffInfo.userId]
      }
      this.loading = true
      rolePermissionApi
        .userBindRole(payload)
        .then(res => {
          if (res.res === true) {
            this.$message.success('绑定角色成功')
            this.visible = false
            this.$emit('refresh')
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
        })
    },
    open(row) {
      this.getAllRole()
      this.visible = true
      this.staffInfo = row
      let roleIds = row.roleVOS.map(item => {
        return item.roleId
      })
      this.$nextTick(() => {
        this.$refs['config-tree'].setCheckedKeys(roleIds) //获取已经设置的资源后渲染
      })
    },
    submit() {
      this.userBindRoles()
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog__body {
  margin-left: 39px !important;
  margin-top: 20px !important;
}
</style>
